import $ from "jquery";

export const bool_fmt = v => {
    if (v) {
        return `<dk-icon value="check:fw" style="color:green"></dk-icon>`;
    } else {
        return `<dk-icon value="minus-circle:fw" style="color:red"></dk-icon>`;
    }
};


class FilterPanel extends dk.Panel {
    constructor(...args) {
        super({
            type: 'FilterPanel3',
            direction: 'left',
            title: 'Filter',
            dataset: null,
        }, ...args);
    }

    construct_panel_body(body) {
        const self = this;
        const filterbox = $('<div style="width: 8em"/>');
        body.append(filterbox);
        this.datafilter = dk.filter.DataFilter.create_on(filterbox, {
            structure: {
                content: {},
            },
            dataset: self.dataset,
            filters: {
                year: {
                    label: "År",
                    select_multiple: false
                },
                active: {
                    label: "Aktiv",
                    values: {
                        0: 'Nei',
                        1: 'Ja'
                    },
                    select_multiple: false
                },
                type: {
                    label: "Type",
                    select_multiple: false
                },
                mandatory: {
                    label: 'IDD-krav',
                    values: {
                        0: 'Nei',
                        1: 'Ja'
                    },
                    select_multiple: false
                }
            }
        });
        return body;
    }
}


export function GoalList(id, datasource_url) {

    return dk.table.ResultSet.create_on(id, {
        dataset: dk.data.DataSet.create({
            pagesize: 15,
            datasource: datasource_url
        }),
        construct_filter: function (location, dataset) {
            return FilterPanel.create(location, {
                dataset: dataset
            });
        },

        command_buttons: {
            add: {
                show: true,
                url: 'add/',
            },
            csv: {
                show: true,
                filename: 'oppdateringskrav.csv',
            },
        },

        construct_table: function (location, downloadwidget, ds) {

            return dk.DataTable.create_on(location, {
                classes: ['table table-bordered table-hover table-condensed'],
                download: downloadwidget,
                table_data: ds,
                pagesize: 15,
                columns: {
                    code: {
                        format: function(val, rec) {
                            return `<a href="/competencyplan/goal/${rec.pk}/">${val}</a>`;
                        },
                        label: "Krav-kode"
                    },
                    name: {
                        label: "Navn"
                    },
                    value: {
                        label: "Verdi"
                    },
                    reward_type: {
                        format: function(val) {
                            return val.name;
                        },
                        label: "Verdi-beskrivelse"
                    },
                    deadline: {
                        label: "Frist"
                    },
                    active: {
                        format: bool_fmt,
                        label: "Aktiv"
                    },
                    mandatory: {
                        format: bool_fmt,
                        label: "IDD-krav"
                    },
                    year: {
                        format: function(val, rec) {
                            return val.year;
                        },
                        label: "År"
                    }

                }
            });
        }
    });
}
$(function() {
    $('section#goallist .excelbtn').on('click', function() {
        const filename = "kompetenasekrav";
        $$.goallist.table.table_data.fetch_csv(filename);
    });
});
