export function CompetencySchedule(location, args) {
    class FilterPanel extends dk.Panel {
        constructor(...args) {
            super({
                type: 'FilterPanel3',
                direction: 'left',
                title: 'Filter',
                dataset: null,
            }, ...args);
        }

        construct_panel_body(location) {
            const self = this;
            const filterbox = $('<div/>');
            location.append(filterbox);
            this.datafilter = dk.filter.DataFilter.create_on(filterbox, {
                structure: {
                    content: {},
                },
                dataset: self.dataset,
                filters: {
                    year: {
                        label: 'Oppdateringsår',
                        select_multiple: false
                    },
                    company: {
                        label: 'Firma',
                        select_multiple: true
                    },
                    department: {
                        label: 'Avdeling',
                        select_multiple: true
                    }
                }
            });
            return location;
        }
    }

    class CompetencySchedule extends dk.ResultSet {
        constructor(...args) {
            const props = Object.assign(...args);
            super({
                dataset: dk.data.Data.create({
                    pagesize: 15,
                    datasource: dk.data.AjaxDataSource.create({
                        url: props.datasource_url
                    })
                })
            }, props);

        }

        construct_filter(location, dataset) {
            return FilterPanel.create_on(location, {
                dataset: dataset,
            });
        }

        construct_table(location, downloadwidget, ds) {
            return dk.DataTable.create_on(location, {
                classes: ['table table-bordered table-hover table-condensed'],
                table_data: ds,
                columns: {
                    username: {
                        label: 'Brukernavn',
                        format: function(val, rec) {
                            return `<a href="${rec.url}">${val}</a>`;
                        }
                    },
                    first_name: {
                        label: 'Fornavn'
                    },
                    last_name: {
                        label: 'Etternavn'
                    },
                    company_name: {
                        label: 'Firma'
                    },
                    department_name: {
                        label: 'Avdeling'
                    },
                    afr: {
                        label: 'S&I',
                    },
                    gos: {
                        label: 'SF',
                    },
                    krd: {
                        label: 'KRD',
                    },
                    aip: {
                        label: 'PF',
                    },
                    sfn: {
                        label: 'SFN',
                    },
                    pfn: {
                        label: 'PFN',
                    },
                    scheduled_goals: {
                        label: 'Oppdateringsemner',
                    }
                }
            });
        }
    }
    return CompetencySchedule.create_on(location, args);
}
