export function CompetencyReport(location, args) {

    class FilterPanel extends dk.Panel {
        constructor(...args) {
            super({
                type: 'FilterPanel3',
                direction: 'left',
                title: 'Filter',
                dataset: null,
            }, ...args);
        }

        construct_panel_body(body) {
            const self = this;
            const filterbox = $('<div/>');
            body.append(filterbox);
            this.datafilter = dk.filter.DataFilter.create_on(filterbox, {
                structure: {
                    content: {},
                },
                dataset: self.dataset,
                filters: {
                    year: {
                        label: 'Oppdateringsår',
                        select_multiple: false
                    },
                    missing_goal: {
                        label: 'Ikke fullført mål',
                        select_multiple: true
                    },
                    completed_goal: {
                        label: 'Fullført mål',
                        select_multiple: true
                    },
                    not_started_goal: {
                        label: 'Ikke påbegynt mål',
                        select_multiple: true
                    },
                    started_goal: {
                        label: 'Påbegynt mål',
                        select_multiple: true
                    },
                    company_name: {
                        label: 'Firma',
                        select_multiple: true
                    },
                    department_name: {
                        label: 'Avdeling',
                        select_multiple: true
                    }
                }
            });
            return body;
        }
    }


    class CompetencyReport extends dk.ResultSet {
        constructor(...args) {
            const props = Object.assign(...args);
            super({
                dataset: dk.data.Data.create({
                    pagesize: 15,
                    datasource: dk.data.AjaxDataSource.create({
                        url: props.competency_report_url
                    })
                })
            }, props);
        }

        construct_filter(location, dataset) {
            return FilterPanel.create(location, {
                dataset: dataset
            });
        }

        construct_table(location, downloadwidget, ds) {
            return dk.DataTable.create_on(location, {
                classes: ['table table-bordered table-hover table-condensed'],
                download: downloadwidget,
                pagesize: 15,
                columns: {
                    username: {
                        label: 'Brukernavn',
                        format(val, rec) {
                            return `<a href="${rec.url}">${val}</a>`;
                        }
                    },
                    first_name: {
                        label: 'Fornavn'
                    },
                    last_name: {
                        label: 'Etternavn'
                    },
                    email: {
                        label: 'Epost'
                    },
                    company_name: {
                        label: 'Firma'
                    },
                    department_name: {
                        label: 'Avdeling'
                    },
                    goals: {}
                }
            });
        }
    }
    return CompetencyReport.create_on(location, args);
}
