import {CompetencyReport} from "./competencyplan";
import {CompetencySchedule} from "./competencyschedule";
import {GoalList} from "./goallist";

const dkcompetencyplan = {
    CompetencyReport,
    CompetencySchedule,
    GoalList
};

export default dkcompetencyplan;
